import React, { Suspense, lazy } from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps, Switch } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../@types/redux';
import { AnimatedLoader, IBaseUserRight, NoRightsScreen, Screen } from '@zz2/zz2-ui';
import { RightGrantsEnum } from '../../@types/enum/rightGrantsEnum';
import FreshServiceActions from '../../store/freshService/actions';

const PrivateRoute = (props : RouteProps) : JSX.Element => {
    const dispatch = useAppDispatch();
    const session = useAppSelector(x => x.auth.session);
    const { component, ...rest } = props;

    const hasMenuRight = (url : string) : boolean => {
        if (!session) return false;
        if (session.user.userRights.length < 1) return false;

        const userRights : Array<IBaseUserRight> = session.user.userRights;
        if (userRights.length < 1) {
            return false;
        } else {
            const urlSections = url.split('/'); 
            const firstPartition = urlSections.slice(0, -1).join('/');
            const filteredUserRights : Array<IBaseUserRight> = userRights.filter(x => !!x.isActive && x.right.url === (urlSections[urlSections.length - 1].includes('Id') 
                ? firstPartition
                : url
            ));

            if (filteredUserRights.length > 0 && filteredUserRights.some(x => x.rightGrantLevel === RightGrantsEnum.View)) {
                return true;
            } else {
                return false;
            }
        }
    };

    const render = (routerProps : RouteComponentProps<any>) : React.ReactNode => {
        if (session) {
            if (!component) return;

            const Component = component;
            if (!hasMenuRight(routerProps.match.url)) {
                return <NoRightsScreen openSupportTicket={() => dispatch(FreshServiceActions.setIsSupportTicketDialogOpen(true))}/>;
            }
            
            return <Component {...routerProps} />;
        }

        return <Redirect to={{ pathname: '/login', state: { from: routerProps.location } }} />;
    };

    return (<Route {...rest} render={render} />);
};

{/* Route Imports */}
const Home = lazy(() => import('../home/Home'));

{/* Master Data */}
const MasterDataHome = React.lazy(() => import('../masterData/masterDataHome'));
const DivisionManager = lazy(() => import('../masterData/division/DivisionManager'));
const SubdivisionManager = lazy(() => import('../masterData/subdivision/SubdivisionManager'));
const DepartmentManager = lazy(() => import('../masterData/department/DepartmentManager'));
const LocationManager = lazy(() => import('../masterData/location/LocationManager'));
const EmployeeManager = lazy(() => import('../masterData/employee/EmployeeManager'));
const EmployeeCardManager = lazy(() => import('../masterData/employeeCard/EmployeeCardManager'));

{/* Transaction */}
const DropOffAllocationManager = lazy(() => import('../dropOffAllocation/DropOffAllocationManager'));

{/* Rights */}
const UserManager = lazy(() => import('../right/userManagement/UserManager'));
const RightsManagement = lazy(() => import('../right/rightsManagement/RightsManager'));

{/* Development Tools*/}
const LogManager = React.lazy(() => import('../logging/LogManager'));
const ContactUsManager = React.lazy(() => import('../home/contactUs/ContactUsManager'));
const NewsManager = React.lazy(() => import('../home/news/NewsManager'));
const FrequentlyAskedQuestionManager = React.lazy(() => import('../home/frequentlyAskedQuestion/FrequentlyAskedQuestionManager'));


const Routes = () : React.ReactElement => {
    return (
        <Suspense fallback={<Screen><AnimatedLoader /></Screen>}>
            <Switch>
                <Route
                    path={'/'} exact
                    render={() : React.ReactElement => <Redirect from={'/'} to={{ pathname: '/home' }} />}
                />
                
                <PrivateRoute exact path='/home' component={Home} />

                {/* MasterData */}
                <PrivateRoute exact path='/masterData' component={MasterDataHome} />
                <PrivateRoute exact path='/masterData/division' component={DivisionManager} />
                <PrivateRoute exact path='/masterData/subdivision' component={SubdivisionManager} />
                <PrivateRoute exact path='/masterData/department' component={DepartmentManager} />
                <PrivateRoute exact path='/masterData/location' component={LocationManager} />
                <PrivateRoute exact path='/masterData/employee' component={EmployeeManager} />
                <PrivateRoute exact path='/masterData/employeeCard' component={EmployeeCardManager} />

                {/* Drop Off Allocation */}
                <PrivateRoute exact path='/dropOffAllocation/transactions' component={DropOffAllocationManager} />

                {/* Rights */}
                <PrivateRoute exact path='/rights/userManagement' component={UserManager} />
                <PrivateRoute exact path='/rights/rightsManagement' component={RightsManagement} />
                
                {/* Development Tools*/}
                <PrivateRoute exact path='/development/tools' component={LogManager} />
                <PrivateRoute exact path='/development/contactUsManager' component={ContactUsManager} />
                <PrivateRoute exact path='/development/newsManager' component={NewsManager} />
                <PrivateRoute exact path='/development/faqManager' component={FrequentlyAskedQuestionManager} />

            </Switch>
        </Suspense>
    );
};

export default Routes;