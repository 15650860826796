
class EnvHelper {
    public static isDevelopmentQa = () => {
        return ENV_NAME === 'qa';
    }

    public static getEnvName = () : string => {
        if (ENV_NAME === 'production') {
            return 'TMS';
        } else if (ENV_NAME === 'qa') {
            return 'TMS QA';
        } else if (ENV_NAME === 'dev') {
            return 'TMS DEV';
        } else {
            return 'TMS ENV NOT FOUND';
        }
    };
}

export default EnvHelper;