import axios from 'axios';
import { IFreshServiceTicketUpsert } from '../../../@types/model/freshService/freshServiceTicketModels';

export default class FreshServiceHttpService {

    public static upsert = async (upsert : IFreshServiceTicketUpsert) : Promise<void> => {
        console.log('in here');
        const result = await axios.post(`${API_URL}/v1/FreshService/Add`, upsert);

        return result.data;
    }
}