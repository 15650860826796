import { IUserToken } from '../../@types/model/right/userToken/userTokenModels';

const SESSION_KEY = 'zz2-tms-session-token';

let sessionCallback : (userToken : IUserToken | null) => void;

/*================================================================================================================
*                                              Sessions
* ==============================================================================================================*/

export async function getLocalStorageSession() {
    let session : IUserToken | null = null;

    session = getSessionLocalStorage();

    if (session) return session;

    return null;
}

export async function setLocalStorageSession(userToken : IUserToken | null) {
    setSessionLocalStorage(userToken);
    
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (sessionCallback) {
        sessionCallback(userToken);
    }
}

function setSessionLocalStorage(userToken : IUserToken | null) {
    if (userToken) {
        localStorage.setItem(SESSION_KEY, JSON.stringify(userToken));
    } else {
        localStorage.removeItem(SESSION_KEY);
    }
}

function getSessionLocalStorage() : IUserToken | null {
    const session = localStorage.getItem(SESSION_KEY);

    if (session) return JSON.parse(session);

    return null;
}

/*================================================================================================================
*                                              Indexed DB
* ==============================================================================================================*/

/**
 * Specifies the callback that will be fired whenever the auth session undergoes a change.
 * @param callback
 */
export async function onSessionChanged(callback : (userToken : IUserToken | null) => void) {
    sessionCallback = callback;

    const session = getSessionLocalStorage();

    sessionCallback(session);
}